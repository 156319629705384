




































import { Component, Watch } from 'vue-property-decorator'
import { Bind, Debounce } from 'lodash-decorators'

import Select from '@/components/_uikit/controls/Select.vue'
import MentorEmployeeModule from '@/store/modules/mentor/employee'
import ManagerGroupsModule from '@/store/modules/manager/groups'
import NotifyMixin from '@/mixins/NotifyMixin'
import {
  ManagerMasterGroupsMasterGroupIdMastersGetParams,
  MasterShortResource,
} from '@/store/types'
import {
  formatDate,
  getCurrentMonth,
} from '@/utils/functions'
import UserCard from '@/components/cards/UserCard.vue'
import ChartExercise from '@/components/charts/ChartExercise.vue'
import ChartLesson from '@/components/charts/ChartLesson.vue'
import Hearts from '@/components/Hearts.vue'
import DictionaryModule from '@/store/modules/dictionary'
import MasterEducationCard from '@/components/cards/MasterEducationCard.vue'

@Component({
  components: {
    ChartExercise,
    ChartLesson,
    Hearts,
    MasterEducationCard,
    Select,
    UserCard,
  },
})
export default class MentorsItemMasters extends NotifyMixin {
  private mastersInGroup: MasterShortResource[] = []
  private filter: ManagerMasterGroupsMasterGroupIdMastersGetParams = {}

  private get mentorID () {
    return +this.$route.params.mentorID
  }

  private get exerciseStatuses () {
    return DictionaryModule.exerciseStatusesFilter
  }

  private get masterGroups () {
    return MentorEmployeeModule.employeeMasterGroups.map(item => ({ name: item.title, value: item.id }))
  }

  private get currentMasterGroup () {
    return MentorEmployeeModule.currentMasterGroup
  }

  private get currentMasterGroupID () {
    if (this.currentMasterGroup) {
      return this.currentMasterGroup.id
    }
    return null
  }

  private set currentMasterGroupID (value: number | null) {
    if (value !== null) {
      const foundMasterGroup = MentorEmployeeModule.employeeMasterGroups.find(item => item.id === value)
      foundMasterGroup && MentorEmployeeModule.setEmployeeCurrentMasterGroup(foundMasterGroup)
    }
  }

  private get months () {
    return this.currentMasterGroup?.months.map(item => ({
      name: formatDate(item.name, 'LLLL yyyy'),
      value: item.value as number,
    }))
  }

  private mounted () {
    this.fetchEmployeeMasterGroups()
  }

  private fetchEmployeeMasterGroups (): void {
    MentorEmployeeModule.fetchEmployeeMasterGroups(this.mentorID)
      .then((response) => {
        if (this.currentMasterGroup && response.length) {
          const currentMonth = getCurrentMonth(this.currentMasterGroup.months)
          if (currentMonth) {
            this.filter = {
              ...this.filter,
              monthId: currentMonth.value as number,
            }
          }
        }
      })
      .catch(this.notifyError)
  }

  private handleRowClick (master: MasterShortResource) {
    this.$router.push({
      name: 'manager.education.masters.item.information',
      params: {
        masterUUID: master.user.uuid,
      },
      query: {
        groupID: (this.currentMasterGroupID as number).toString(),
      },
    })
  }

  @Bind
  @Debounce(300)
  private fetchMastersInGroup () {
    if (this.currentMasterGroup) {
      ManagerGroupsModule.fetchMasterGroupsSelect({
        masterGroupID: this.currentMasterGroupID as number,
        params: this.filter,
      })
        .then(response => this.mastersInGroup = response.filter(item => item.user.id !== this.mentorID))
        .catch(this.notifyError)
    }
  }

  @Watch('filter', { deep: true })
  watchFilter () {
    this.fetchMastersInGroup()
  }

  @Watch('currentMasterGroupID')
  watchCurrentMasterGroupID (value: number) {
    const foundMasterGroup = MentorEmployeeModule.employeeMasterGroups.find(item => item.id === value)
    if (foundMasterGroup) {
      const currentMonth = getCurrentMonth(foundMasterGroup?.months)
      if (currentMonth) {
        this.filter = {
          ...this.filter,
          monthId: currentMonth.value as number,
        }
      }
    }
  }
}
